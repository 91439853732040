#customers {

  .main-sidenav {

    md-list {

      md-list-item {

        &.selected {

          .md-button {
            font-weight: 500;
            color: #000000;
          }

        }

        &.new-group-form {

          .editable-text {
            width: 100%;

            .editable-controls {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              padding-left: 8px;

              .editable-input {
                border: 1px solid rgba(0, 0, 0, 0.2);
                padding: 4px 2px;
                margin-bottom: 8px;
                font-size: 13px;
                width: 100%;
              }

              .editable-buttons {

                .md-button {
                  margin: 0;
                  margin: 0;
                  padding: 0;
                  height: 32px;
                  min-height: 32px;
                  line-height: 32px;
                  width: 32px;
                }

              }
            }
          }
        }
      }
    }
  }
}