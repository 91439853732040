#e-commerce-products {

  position: relative;

  > .header {
    height: 76px;
    min-height: 76px;
    max-height: 76px;

    .logo {

      .logo-icon {
        margin: 0 16px 0 0;
      }

      .logo-text {
        font-size: 24px;
      }
    }

    md-icon {
      color: rgba(0,0,0,0.54);
    }

    .search-input-wrapper {
      position: relative;

      label {
        padding-right: 4px;
        cursor: pointer;

        md-icon {
          color: rgba(0,0,0,0.54);
        }

      }

      md-input-container {

        .md-errors-spacer {
          display: none;
        }

        input {

          width: 240px;
          border: none;


          &::placeholder {

          }

          &:focus {

          }

        }
      }
    }
  }

  > .content {
    position: absolute;
    top: 76px;
    bottom: 0;
    right: 0;
    left: 0;

  }



  #quantity-indicator {
    display: inline-block;
    vertical-align: middle;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin-right: 8px;
  }




}


#e-commerce-products-product {

  position: relative;

  .mce-content-body table caption, .mce-content-body table td, .mce-content-body table th {
    padding: 15px 7px;
    font: inherit;
  }


  .angular-ui-tree-handle {
    font-weight:normal;
  }


  > .header {

    min-height: 76px;
    max-height: 116px;

    .logo {

      .logo-icon {
        margin: 0 16px 0 0;
      }

      .logo-text {
        font-size: 24px;
      }
    }

  }

  .search-input-wrapper {
    position: relative;

    label {
      padding-right: 4px;
      cursor: pointer;

      md-icon {
        color: rgba(0,0,0,0.54);
      }

    }

    md-input-container {

      .md-errors-spacer {
        display: none;
      }

      input {

        width: 240px;
        border: none;


        &::placeholder {

        }

        &:focus {

        }

      }
    }
  }

  #image-delete {
    position: relative;
    vertical-align: middle;
    display: inline-block;

    button {
      position:absolute;
      right:0;
      top:0;
    }
  }

  #md-toast {

    .success {
      background-color: green;
    }

    .error {
      background-color: red;
    }
  }




}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-md) {

  #customers {

    > .content {

      .main {
        left: 24px;
      }

      > md-sidenav {
        padding: 0;
      }

    }
  }
}