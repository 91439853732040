/*----------------------------------------------------------------*/
/*  Page Layouts
/*----------------------------------------------------------------*/

// Page Layouts
$header-height: 200px;
$card-toolbar-height: 64px;
$card-header-height: $header-height - $card-toolbar-height;

$header-height-xs: 160px;
$card-header-height-sm: $header-height-xs - $card-toolbar-height;

.page-layout {
    position: relative;
    overflow: hidden;

    .top-bg {
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        left: 0;
        height: $header-height;
        background-image: url('/assets/images/backgrounds/header-bg.png');
        background-size: cover;
    }

    md-backdrop {
        z-index: 50;
    }

    // Global header styles
    > .header {

        .breadcrumb {
            margin-bottom: 16px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.54);

            md-icon {
                margin: 0;
            }

            .separator {
                margin: 0 8px;
            }
        }

        .title {
            font-size: 34px;
        }
    }

    // Carded layout
    &.carded {
        min-height: 100%;
        height: 100%;

        // Fullwidth
        &.fullwidth {

            // Single scroll
            &.single-scroll {
                height: auto;

                > .center {

                    .content-card {

                        .content {
                            overflow: hidden;
                        }
                    }
                }
            }

            // Center
            > .center {
                position: relative;
                z-index: 2;
                margin-left: 32px;
                margin-right: 32px;

                .header {
                    height: $card-header-height;
                    min-height: $card-header-height;
                    max-height: $card-header-height;
                    padding: 24px;
                }

                .content-card {
                    background: #FFFFFF;
                    box-shadow: $whiteframe-shadow-7dp;
                    overflow: hidden;

                    .toolbar {
                        padding: 8px 24px;
                        height: $card-toolbar-height;
                        min-height: $card-toolbar-height;
                        max-height: $card-toolbar-height;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                    }

                    .content {
                        padding: 24px;
                        background: #FFFFFF;
                    }
                }
            }
        }

        // Left sidenav
        &.left-sidenav {

            // Single scroll
            &.single-scroll {
                height: auto;

                > .center {

                    .content-card {

                        .content {
                            overflow: hidden;
                        }
                    }
                }
            }

            // Sidenav
            > .sidenav {
                width: 240px;
                min-width: 240px;
                max-width: 240px;
                box-shadow: $whiteframe-shadow-7dp;
                z-index: 51;

                .header {
                    height: $header-height;
                    min-height: $header-height;
                    max-height: $header-height;
                    padding: 24px;
                }

                .content {
                    background: transparent;
                    padding: 24px;
                }

                &.md-locked-open {
                    width: 220px;
                    min-width: 220px;
                    max-width: 220px;
                    z-index: 2;
                    background: transparent;
                    box-shadow: none;

                    + .center {
                        margin-left: 0;
                    }
                }

                &:not(.md-locked-open) {

                    .header {
                        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                    }

                    .content {
                        overflow: hidden;
                    }
                }
            }

            // Center
            > .center {
                position: relative;
                z-index: 2;
                margin-left: 32px;
                margin-right: 32px;

                .header {
                    height: $card-header-height;
                    min-height: $card-header-height;
                    max-height: $card-header-height;
                    padding: 24px;
                }

                .content-card {
                    background: #FFFFFF;
                    box-shadow: $whiteframe-shadow-7dp;
                    overflow: hidden;

                    .toolbar {
                        padding: 8px 24px;
                        height: $card-toolbar-height;
                        min-height: $card-toolbar-height;
                        max-height: $card-toolbar-height;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.12);

                        .sidenav-toggle {
                            height: $card-toolbar-height;
                            line-height: $card-toolbar-height;
                            margin: 0 !important;
                            padding: 0 8px 0 0 !important;
                            border-radius: 0;
                        }
                    }

                    .content {
                        padding: 24px;
                        background: #FFFFFF;
                    }
                }
            }
        }

        // Right sidenav
        &.right-sidenav {

            // Single scroll
            &.single-scroll {
                height: auto;

                > .center {

                    .content-card {

                        .content {
                            overflow: hidden;
                        }
                    }
                }
            }

            // Sidenav
            > .sidenav {
                width: 240px;
                min-width: 240px;
                max-width: 240px;
                box-shadow: $whiteframe-shadow-7dp;
                z-index: 51;

                .header {
                    height: $header-height;
                    min-height: $header-height;
                    max-height: $header-height;
                    padding: 24px 24px 24px 0;
                }

                .content {
                    background: transparent;
                    padding: 24px 24px 24px 0;
                }

                &.md-locked-open {
                    width: 196px;
                    min-width: 196px;
                    max-width: 196px;
                    z-index: 2;
                    background: transparent;
                    box-shadow: none;
                }

                &:not(.md-locked-open) {

                    .header {
                        padding: 24px;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                    }

                    .content {
                        overflow: hidden;
                        padding: 24px;
                    }
                }
            }

            // Center
            > .center {
                position: relative;
                z-index: 2;
                margin-left: 32px;
                margin-right: 32px;

                .header {
                    height: $card-header-height;
                    min-height: $card-header-height;
                    max-height: $card-header-height;
                    padding: 24px;
                }

                .content-card {
                    background: #FFFFFF;
                    box-shadow: $whiteframe-shadow-7dp;
                    overflow: hidden;

                    .toolbar {
                        padding: 8px 24px;
                        height: $card-toolbar-height;
                        min-height: $card-toolbar-height;
                        max-height: $card-toolbar-height;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.12);

                        .sidenav-toggle {
                            height: $card-toolbar-height;
                            line-height: $card-toolbar-height;
                            margin: 0 !important;
                            padding: 0 0 0 8px !important;
                            border-radius: 0;
                        }
                    }

                    .content {
                        padding: 24px;
                        background: #FFFFFF;
                    }
                }
            }
        }
    }

    // Simple layout
    &.simple {

        // Fullwidth
        &.fullwidth,
        &.inner-sidenav {
            min-height: 100%;

            > .header {
                height: $header-height;
                min-height: $header-height;
                max-height: $header-height;
                padding: 24px;
                //background-image: url('/assets/images/backgrounds/header-bg.png');
                //background-size: cover;
                background-color: rgb(245,245,245);
                color: rgb(117,117,117);
            }

            > .content {
                padding: 24px;
            }
        }

        // Left sidenav
        &.left-sidenav {
            min-height: 100%;
            height: 100%;

            // Single scroll
            &.single-scroll {
                height: auto;

                > .center {
                    overflow: hidden;
                }
            }

            // Inner Left Sidenav
            &.inner-sidenav {
                > .content {
                    > md-sidenav {
                        margin-right: 24px;
                    }
                }
            }

            .sidenav {
                width: 240px;
                min-width: 240px;
                max-width: 240px;
                padding: 24px;
                z-index: 51;
                box-shadow: $whiteframe-shadow-7dp;

                &.md-locked-open {
                    width: 220px;
                    min-width: 220px;
                    max-width: 220px;
                    z-index: 2;
                    box-shadow: none;
                    background: transparent;
                }
            }

            > .center {
                position: relative;
                overflow: auto;
                z-index: 3;
                box-shadow: $whiteframe-shadow-7dp;

                .header {
                    height: $header-height;
                    min-height: $header-height;
                    max-height: $header-height;
                    padding: 24px;
                    //background-image: url('/assets/images/backgrounds/header-bg.png');
                    //background-size: cover;
                }

                .content {
                    padding: 24px;
                    background: #FFFFFF;
                }
            }
        }

        // Right sidenav
        &.right-sidenav {
            min-height: 100%;
            height: 100%;

            // Single scroll
            &.single-scroll {
                height: auto;

                > .center {
                    overflow: hidden;
                }
            }

            // Inner Right Sidenav
            &.inner-sidenav {
                > .content {
                    > md-sidenav {
                        margin-left: 24px;
                    }
                }
            }

            .sidenav {
                width: 240px;
                min-width: 240px;
                max-width: 240px;
                padding: 24px;
                z-index: 51;
                box-shadow: $whiteframe-shadow-7dp;

                &.md-locked-open {
                    width: 220px;
                    min-width: 220px;
                    max-width: 220px;
                    z-index: 2;
                    box-shadow: none;
                    background: transparent;
                }
            }

            > .center {
                position: relative;
                overflow: auto;
                z-index: 3;
                box-shadow: $whiteframe-shadow-7dp;

                .header {
                    height: $header-height;
                    min-height: $header-height;
                    max-height: $header-height;
                    padding: 24px;
                    //background-image: url('/assets/images/backgrounds/header-bg.png');
                    //background-size: cover;
                }

                .content {
                    padding: 24px;
                    background: #FFFFFF;
                }
            }
        }

        // Inner Sidenav
        &.inner-sidenav {
            height: auto;
            > .content {
                > md-sidenav.md-locked-open {
                    padding: 0;
                }
            }
        }

        // Tabbed
        &.tabbed {
            min-height: 100%;

            > .header {
                height: $header-height;
                min-height: $header-height;
                max-height: $header-height;
                padding: 24px;
                background-image: url('/assets/images/backgrounds/header-bg.png');
                background-size: cover;
            }

            > .content {

                > md-tabs {

                    > md-tabs-wrapper {
                        background: #FFFFFF;
                        box-shadow: $whiteframe-shadow-1dp;
                        padding: 0 24px;

                        md-tabs-canvas {
                            height: 56px;

                            md-pagination-wrapper {
                                height: 56px;
                            }

                            .md-tab {
                                padding: 16px 24px;
                                text-transform: none;
                            }
                        }
                    }

                    &:not(.md-dynamic-height) {

                        md-tabs-content-wrapper {
                            top: 56px;
                        }
                    }

                    > md-tabs-content-wrapper {

                        > md-tab-content {
                            padding: 24px;
                        }
                    }
                }
            }
        }
    }

    // Blank layout
    &.blank {
        min-height: 100%;
        padding: 24px;
    }
}

// Single scroll modifications
.single-scroll {

    // Carded
    .carded {

        // Fullwidth
        &.fullwidth {
            height: auto;

            > .center {

                .content-card {

                    .content {
                        overflow: hidden;
                    }
                }
            }
        }

        // Left sidenav
        &.left-sidenav {
            height: auto;

            > .center {

                .content-card {

                    .content {
                        overflow: hidden;
                    }
                }
            }
        }

        // Right sidenav
        &.right-sidenav {
            height: auto;

            > .center {

                .content-card {

                    .content {
                        overflow: hidden;
                    }
                }
            }
        }
    }

    // Simple layout
    .simple {

        // Left sidenav
        &.left-sidenav {
            height: auto;

            > .center {
                overflow: hidden;
            }
        }

        // Right sidenav
        &.right-sidenav {
            height: auto;

            > .center {
                overflow: hidden;
            }
        }
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-md) {

    .page-layout {

        &.simple {

            &.inner-sidenav {

                &.right-sidenav, &.left-sidenav {

                    > .content {
                        height: auto !important;

                        > md-sidenav {
                            margin-left: 0;
                            margin-right: 0;
                        }

                    }
                }
            }
        }
    }

    .sidenav-open {

        .page-layout {

            &.simple {

                &.inner-sidenav {
                    height: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: $layout-breakpoint-xs) {

    .page-layout {

        .top-bg {
            height: $header-height-xs;
        }

        &.carded {

            &.right-sidenav,
            &.left-sidenav,
            &.fullwidth {

                > .center {
                    margin-left: 16px;
                    margin-right: 16px;

                    .header {
                        height: $card-header-height-sm;
                        min-height: $card-header-height-sm;
                        max-height: $card-header-height-sm;
                        padding: 16px;
                    }
                }
            }

            // Turn carded layouts into single-scroll
            &.fullwidth {
                height: auto;

                > .center {

                    .content-card {

                        .content {
                            overflow: hidden;
                        }
                    }
                }
            }

            &.right-sidenav,
            &.left-sidenav {
                height: auto;

                > .center {

                    .content-card {

                        .content {
                            overflow: hidden;
                        }
                    }
                }
            }
        }

        &.simple {

            &.fullwidth,
            &.inner-sidenav {

                > .header {
                    height: $header-height-xs;
                    min-height: $header-height-xs;
                    max-height: $header-height-xs;
                    padding: 16px;
                }
            }

            &.right-sidenav,
            &.left-sidenav {

                > .center {

                    .header {
                        height: $header-height-xs;
                        min-height: $header-height-xs;
                        max-height: $header-height-xs;
                        padding: 16px;
                    }
                }
            }
        }

        // Turn simple layouts into single-scroll
        &.simple {

            &.left-sidenav,
            &.right-sidenav {
                height: auto;

                > .center {
                    overflow: hidden;
                }
            }
        }
    }

}